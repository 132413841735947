import { Directive, HostBinding, inject, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AUTH_MATRIX } from 'src/app/data/authMatrix';

@Directive({
  selector: '[appCanActivatePath]',
  standalone: true,
})
export class CanActivatePathDirective implements OnInit {
  @Input() appCanActivatePath: keyof typeof AUTH_MATRIX;
  @Input() routerLink: string;
  @HostBinding('style.display') display!: string;
  @HostBinding('attr.hidden') hidden!: string;

  authService = inject(AuthService);

  ngOnInit() {
    const path =
      this.appCanActivatePath || this.routerLink?.replace(/^\/+/, '');
    this.authService.canActivatePath(path).subscribe((value) => {
      if (!value) this.display = 'none';
      this.hidden = (!value).toString();
    });
  }
}
